import React from "react"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../../components/seo"
import Layout from "../../app/layout"
import { Input } from "antd"

const SearchPage = ({ data: { aboutVisionMissions: data = {} } }) => {
  const { t } = useI18next()

  return (
    <Layout>
      <Seo title={data.header_banner_title} />
      <div>
        <div>
          <Input.Search placeholder={t("Cari di sini...")} />
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutVisionMissions(lang: { eq: $language }) {
      header_banner_image
      header_banner_title
      header_slug
      middle_banner_image
      mission_content
      mission_title
      our_focus_content
      our_focus_title
      updated_at
      vision_content
      vision_title
    }
  }
`
